<template>
  <DargLayout
    v-model="list"
    :report-form="reportForm"
    :visible.sync="drawerEditVisible"
    :active-item-prop.sync="activeItem"
  >
    <!-- 右键菜单 -->
    <template #rightMenu>
      <div class="item" @click="drawerEditVisible = true">编辑</div>
      <div v-if="activeItem.placeholderType !== 4" class="item" @click="onDel(activeItem)">删除</div>
      <div v-if="!isReportTitle" class="item" @click="insertReportTitle">插入报告标题</div>
      <div v-if="activeItem.placeholderType === 3" class="item" @click="insert('title')">插入标题</div>
      <div v-if="activeItem.placeholderType === 3" class="item" @click="insert('analysis')">插入分析</div>
    </template>

    <!-- darg-item -->
    <template #dargItem="{ item }">
      <!-- <p v-if="item.placeholderType === 4" style="text-align: right">报告更新时间：{{ item.placeholder }}</p> -->
      <h1 v-if="item.placeholderType === 5" style="text-align: center;">{{ item.placeholder }}</h1>
      <draggable-item-html v-if="item.placeholderType === 1 || item.placeholderType === 2 || item.placeholderType === 4" :item="item" />
      <data-chart
        v-if="item.placeholderType === 3"
        v-loading="item.loading"
        class="data-chart"
        :show-type-name="item.showTypeName || 'table'"
        :data="item.data"
      />
    </template>

    <template #drawer>
      <span class="toop-tip">布局项总占位宽度为24,高度每份15像素</span>
      <el-form
        ref="activeItemForm"
        :rules="activeItemRules"
        size="small"
        label-suffix=":"
        :model="activeItem"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="宽" prop="w">
              <el-input-number
                v-model="activeItem.w"
                :step="1"
                :min="0"
                step-strictly
                controls-position="right"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="高" prop="h">
              <el-input-number
                v-model="activeItem.h"
                :step="1"
                :min="0"
                step-strictly
                controls-position="right"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item v-if="activeItem.placeholderType === 4 || activeItem.placeholderType === 5" label="标题" prop="placeholder">
            <el-col :span="20">
              <el-input v-model.trim="activeItem.placeholder" placeholder="可修改标题" />
            </el-col>
          </el-form-item>
        </el-row>
        <EditingTools
          v-if="activeItem.placeholderType === 2 || activeItem.placeholderType === 1"
          v-model="activeItem.placeholder"
          class="editor"
          classify="115"
        />
        <!-- <editor
          v-if="activeItem.placeholderType === 2 || activeItem.placeholderType === 1"
          ref="editor"
          :content="activeItem.placeholder"
          class="editor"
          classify="115"
        /> -->
      </el-form>
    </template>
  </DargLayout>
</template>

<script>
import { nanoid } from 'nanoid'
import DraggableItemHtml from '../../components/draggable-item-html.vue'
// import Editor from '@/components/Editor.vue'
import DargLayout from '../../components/darg-layout.vue'

import { queryModelData, detail } from '@/apis/reportService.js'
import { defaultOrgId } from '@/utils/util'
export default {
  name: 'CreateOrEditStep2',
  components: {
    // Editor,
    DraggableItemHtml,
    DargLayout
  },
  props: {
    reportForm: {
      type: Object,
      default: Object
    },
    showTypeText: Function
  },
  data() {
    return {
      drawerEditVisible: false,
      activeItem: {},
      activeItemRules: {
        w: [{required: true, message: '请输入报表宽度', trigger: 'blur'}],
        h: [{required: true, message: '请输入报表高度', trigger: 'blur'}],
        placeholder: [{required: true, message: '请输入标题', trigger: 'blur'}]
      },
      rightMenu: {
        show: false,
        style: {}
      },
      form: {
        colNum: 24,
        gutter: 10
      },
      list: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  computed: {
    infoTitle() {
      return {
        x: 0,
        y: 0,
        w: 24,
        h: 4,
        placeholderType: 5,
        placeholder: this.reportForm.reportModelName,
        i: nanoid()
      }
    },
    isReportTitle() {
      return this.list.some(item => item.placeholderType === 5)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.$route.query.reportModelId) {
        this.getDetails(this.$route.query.reportModelId)
      } else {
        const list = this.reportForm.reportAndModelDetailList.map(item => {
          item.placeholderType = 3
          item.smartModelId = item.modelId
          return item
        })
        this.insertReportTitle()
        this.getChartsFormatData(list)
      }
    },
    onSaveRichText() {
      this.list = this.list.map(item => {
        if (item.i === this.activeItem.i) {
          if (this.activeItem.placeholderType === 2 || this.activeItem.placeholderType === 1) {
            this.activeItem.placeholder = this.$refs.editor.editor.txt.html()
          }
          return this.activeItem
        }
        return item
      })
      this.$refs['grid-layout'].layoutUpdate()
      this.drawerEditVisible = false
    },
    // 查询报告模板详情
    async getDetails(reportModelId) {
      try {
        const res = await detail({ reportModelId })
        const { reportAndModelDetailVOList } = res
        this.getChartsFormatData(reportAndModelDetailVOList)
      } catch (error) {
        console.log(error)
      }
    },
    getChartsFormatData(arr) {
      arr.forEach((chartItem, index) => {
        this.getChartItemData(chartItem, index)
      })
    },
    // 获取单个报表的数据
    async getChartItemData(chartItem, index = 0, type = 'add') {
      const { x, y, w, h, i, placeholderType, placeholderStyle = '{}', placeholder, smartModelId } = chartItem
      const obj = {
        x: x || (index % 2) * 12,
        y: y || 0,
        w: w || 12,
        h: h || 18,
        placeholderType,
        placeholder,
        smartModelId,
        i: i || smartModelId || nanoid(),
        ...JSON.parse(placeholderStyle)
      }
      if (type === 'add') {
        this.list.push(obj)
      }
      if (placeholderType === 3) {
        obj.loading = true
        try {
          const res = await this.getChartData(chartItem, defaultOrgId())
          this.list = this.list.map(item => {
            if (item.placeholderType === 3 && item.smartModelId === smartModelId) {
              item.data = res
              item.data.modelName = res?.desc.modelName
              item.showTypeName = this.showTypeText(res.desc.showType)?.value
              item.loading = false
            }
            return item
          })
        } catch (error) {
          this.list = this.list.map(item => {
            if (item.placeholderType === 3) {
              item.loading = false
            }
            return item
          })
        }
      }
    },
    async getChartData({ smartModelId: modelId }, orgId) {
      try {
        return await queryModelData({
          modelId,
          orgId,
          containsIsDel: true
        })
      } catch (error) {
        console.log(error)
      }
    },
    // 拖拽结束，添加报表
    updateChartItem(row, type) {
      if (type === 'add') {
        console.log('弹窗')
      } else {
        this.getChartItemData(row, 0, type)
      }
    },
    // 插入报告标题
    insertReportTitle() {
      this.list.unshift(this.infoTitle)
    },
    // 插入分析
    insert(type) {
      this.list.some((item, index) => {
        if (item.i === this.activeItem.i) {
          const obj = {
            i: nanoid(),
            x: item.x,
            y: item.y,
            w: item.w,
            h: 3,
            placeholder: '请输入要插入的内容'
          }
          if (type === 'title') {
            obj.placeholderType = 1
            this.list.splice(index === 0 ? 0 : index - 1, 0, obj)
          } else {
            obj.placeholderType = 2
            this.list.splice(index + 1, 0, obj)
          }
          this.activeItem = obj
          return true
        }
        return false
      })
      this.drawerEditVisible = true
    },
    // 删除
    onDel(row) {
      this.$confirm('此操作将删除该项内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.list = this.list.filter(item => item.i !== row.i)
        this.$message({
          type: 'success',
          message: '删除成功'
        })
      })
    },
    // 验证表单
    validate() {
      return new Promise((reslove, reject) => {
        this.$refs.activeItemForm.validate(valid => {
          if (valid) {
            reslove()
          } else {
            console.log('error submit!!')
            reject()
            return false
          }
        })
      })
    },
    submit() {
      return new Promise((resolve, reject) => {
        const [row] = this.list.filter(item => {
          return item.placeholderType === 5
        })
        if (this.list.length === 0) {
          reject()
          return this.$message.error('报告模板不能为空')
        }  else if (!row) {
          return this.$message.error('请右键添加报告标题')
        }  else if (!row.placeholder) {
          return this.$message.error('报告名称不能为空')
        } else {
          const obj = {
            reportAndModelDetailList: [],
            reportModelId: this.$route.query.reportModelId,
            reportModelName: this.reportForm.reportModelName
          }
          this.list.forEach(({ smartModelId, placeholder = '', placeholderType = '', x, y, w, h }) => {
            if (placeholderType === 5) {
              obj.reportModelName = placeholder
            }
            obj.reportAndModelDetailList.push({
              smartModelId,
              placeholder,
              placeholderType,
              placeholderStyle: JSON.stringify({ x, y, w, h })
            })
          })
          resolve(obj)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.drawer {
  :deep(.el-drawer__body) {
    padding: 10px;
    display: flex;
    flex-direction: column;
    .toop-tip {
      margin-bottom: 10px;
      font-size: 12px;
      color: red;
    }
    .editor {
      flex: 1;
      > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        .w-e-text-container {
          .w-e-text {
            min-height: 300px;
          }

          height: inherit !important;
          flex: 1;
        }
      }
    }
  }
}
.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  .context-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    .editor {
      flex: 1;
    }
  }
}
.drawer__footer {
  padding-top: 10px;
}
.right-menu {
  width: 200px;

  --bg1: hsla(0, 0%, 98%, 0.9);
  --bg1a: hsla(0, 0%, 97%, 0.96);
  --bg2: rgba(0, 0, 0, 0.08);

  -webkit-backdrop-filter: saturate(2) blur(20px);
  backdrop-filter: saturate(2) blur(20px);
  background: var(--bg1);
  border-radius: 8px;
  opacity: 1;
  position: absolute;
  transition: opacity 0.1s ease 0.3s;
  cursor: default;
  z-index: 3;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  .item {
    transition: all 0.3s;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    color: var(--txt-col);
    display: flex;
    font-size: 12px;
    font-weight: 500;
    height: 28px;
    margin: 6px;
    padding: 4px 6px;
    &:hover {
      background: var(--bg2);
    }
  }
}
</style>
