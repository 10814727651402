<template>
  <div class="create-or-edit">
    <div style="text-align: right;">
      <el-button type="text" icon="el-icon-back" @click="$router.back()">返回上一页</el-button>
    </div>
    <div class="context">
      <el-steps
        v-if="!$route.query.reportModelId"
        style="margin-bottom: 10px;"
        :active="active"
        align-center
        finish-status="success"
      >
        <el-step title="第一步：选择报表模板" />
        <el-step title="第二步：查看报告模板" />
      </el-steps>
      <CreateOrEditStep1
        v-if="active === 0 && !$route.query.reportModelId"
        ref="tempalte-create-or-edit-step0"
        v-model="reportForm.reportAndModelDetailList"
        :show-type-text="showTypeText"
      />
      <CreateOrEditStep2
        v-if="active === 1"
        ref="tempalte-create-or-edit-step1"
        :show-type-text="showTypeText"
        :report-form="reportForm"
      />
    </div>
    <footer-bottom-box style="text-align: center;">
      <template v-if="active === 0">
        <el-button :disabled="active === 1" @click="onSubmit">下一步</el-button>
      </template>
      <template v-if="active === 1">
        <el-button v-if="!$route.query.reportModelId" :disabled="active === 0" @click="preStep">上一步</el-button>
        <el-button @click="onSaveReport">保存模板</el-button>
      </template>
    </footer-bottom-box>

    <el-dialog title="报告模板" :visible.sync="dialogVisible" width="600px">
      <el-form
        size="small"
        :model="reportForm"
        label-width="120px"
        label-suffix="："
      >
        <el-form-item label="报告模板名称">
          <el-input v-model="reportForm.reportModelName" placeholder="请输入报告模板名称" />
        </el-form-item>
        <el-form-item label="已选择的报表">
          <el-table
            max-height="300"
            size="small"
            border
            :data="reportForm.reportAndModelDetailList"
          >
            <el-table-column prop="modelName" label="图表名称" />
            <el-table-column prop="showType" label="类型">
              <template slot-scope="scoped">
                {{ showTypeText(scoped.row.showType).label }}
              </template>
            </el-table-column>
            <el-table-column prop="refCount" label="引用次数" />
          </el-table>
        </el-form-item>
        <el-form-item label="选择开启位置">
          <el-checkbox-group v-model="reportForm.radio3">
            <el-checkbox label="1">科会详情页</el-checkbox>
            <el-checkbox label="2">内部使用</el-checkbox>
            <el-checkbox label="2">直播详情页</el-checkbox>
            <el-checkbox label="2">会议详情页</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="nextStep">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CreateOrEditStep1 from './components/CreateOrEditStep1.vue'
import CreateOrEditStep2 from './components/CreateOrEditStep2.vue'

import { showTypeText } from '../utils.js'
import { saveOrUpdate } from '@/apis/reportService.js'
export default {
  name: 'ReportTemplateCreateOrEdit',
  components: { CreateOrEditStep1, CreateOrEditStep2 },
  data() {
    return {
      active: 0,
      dialogVisible: false,
      loading: false,
      reportForm: {
        reportModelName: '',
        reportAndModelDetailList: [],
        radio3: []
      }
    }
  },
  computed: {
    showTypeText,
    actStep() {
      return this.$refs[`tempalte-create-or-edit-step${this.active}`]
    }
  },
  mounted() {
    this.active = this.$route.query.reportModelId ? 1 : 0
  },
  methods: {
    preStep() {
      this.active--
    },
    onSubmit() {
      this.actStep.submit().then(res => {
        this.reportForm.reportAndModelDetailList = res
        this.dialogVisible = true
      })
    },
    stepToAdd() {
      this.actStep.dialogVisible = true
    },
    stepToSaveAsPdf() {
      this.actStep.saveAsPdf()
    },
    nextStep() {
      if (!this.reportForm.reportModelName) {
        this.$message.error('请输入报告名')
        return false
      }
      this.active++
      this.dialogVisible = false
    },
    async onSaveReport() {
      try {
        const params = await this.actStep.submit()
        this.loading = true
        const res = await saveOrUpdate(params)
        if (res) {
          this.$message.success('保存成功')
          this.$router.back()
        }
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.create-or-edit {
  display: flex;
  flex-direction: column;
  .context {
    flex: 1;
  }
}
</style>
