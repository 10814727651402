<template>
  <!-- 数据报告模板 -->
  <div class="page-main">
    <search-wrap class="search-wrap">
      <el-form inline label-suffix="：">
        <el-form-item label="报告模板名称">
          <el-input v-model="search.reportModelName" placeholder="请输入报告模板名称" />
        </el-form-item>
        <el-form-item label="创建人">
          <el-input v-model="search.creatorName" placeholder="请输入创建人名称" />
        </el-form-item>
        <el-button @click="onSearch">搜索</el-button>
      </el-form>
      <div class="handle-btn">
        <el-button
          type="primary"
          icon="el-icon-plus"
          plain
          @click="onAdd"
        >
          新建数据报告模板
        </el-button>
      </div>
    </search-wrap>
    <!-- 处理表格区 -->
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @sort-change="onSortChange"
    >
      <el-table-column prop="modelName" label="报告模板名称" />
      <el-table-column prop="status" label="状态" width="200">
        <template slot-scope="scoped">
          {{ scoped.row.status === 0 ? '未发布' : scoped.row.status === 1 ? '预发' : '已发布' }}
        </template>
      </el-table-column>
      <el-table-column prop="creatorName" label="创建人" width="200" />
      <el-table-column prop="refCount" label="引用次数" width="200" />
      <el-table-column prop="updatedAt" width="200" label="报告模板更新日期" />
      <!-- <el-table-column prop="createdAt" width="200" label="报告模板生成日期" /> -->
      <el-table-column prop="createdAt" width="200" label="发布位置" />
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scoped">
          <el-button type="primary" size="mini" @click="onCheck(scoped.row)">查看</el-button>
          <el-button
            v-if="scoped.row.status === 1"
            type="warning"
            size="mini"
            @click="onEdit(scoped.row)"
          >
            开启
          </el-button>
          <el-button
            v-if="scoped.row.status === 2"
            type="primart"
            size="mini"
            @click="onEdit(scoped.row)"
          >
            关闭
          </el-button>
          <el-button type="danger" size="mini" @click="onDel(scoped.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import { reportModelPageList, reportModelDelete, updateStatus } from '@/apis/reportService.js'
export default {
  name: 'DataReportManagement',
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        reportModelName: '',
        creatorName: ''
      },
      loading: false,
      tableData: []
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    // 获取列表数据
    async getDataList() {
      this.loading = true
      const params = this.getParams(this.search)
      try {
        const { data, totalCount } = await reportModelPageList(params)
        this.loading = false
        this.tableData = data
        this.pagination.total = totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    onAdd({ reportModelId }) {
      this.$router.push({
        name: 'ReportTemplateCreateOrEdit',
        query: {
          reportModelId
        }
      })
    },
    async onDel({ reportModelId }) {
      try {
        await this.$confirm('此操作将永久删除该报告模板, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const res = await reportModelDelete({ reportModelId })
        this.$message.success('删除成功')
        this.getDataList()
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    // 搜索
    queryData() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    // 编辑
    async onEdit({ reportModelId, status }) {
      try {
        status = status === 0 ? 1 : status === 1 ? 2 : 0
        console.log(reportModelId, status)
        const res = await updateStatus({ reportModelId, status })
        console.log(res)
        this.getDataList()
        this.$message.success('编辑成功')
      } catch (error) {
        console.log(error)
      }
    },
    // 查看
    onCheck(row) {
      this.$router.push({
        name: 'ReportTemplateCreateOrEdit',
        query: {
          reportModelId: row.reportModelId
        }
      })
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    }
  }
}
</script>
<style lang="scss" scoped>
// scss
.page-main {
  .search-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
.handle-btn {
  margin-bottom: 10px;
}
.textarea {
  width: 400px;
}
.pagination-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
