<template>
  <div class="create-or-edit-step1">
    <el-tabs v-model="search.categoryId" type="card" @tab-click="handleClick">
      <el-tab-pane label="全部" name="" />
      <el-tab-pane
        v-for="item in classifyList"
        :key="item.categoryId"
        :label="item.categoryName"
        :name="item.categoryId"
      />
    </el-tabs>
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      :row-key="row => row.modelId"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        class="selection"
        align="center"
        type="selection"
        :reserve-selection="true"
        width="55"
      />
      <el-table-column prop="modelName" label="图表名称" />
      <el-table-column prop="showType" width="200" label="类型">
        <template slot-scope="scoped">
          {{ showTypeText(scoped.row.showType).label }}
        </template>
      </el-table-column>
      <el-table-column prop="refCount" width="200" label="引用次数" />
      <el-table-column prop="createdAt" width="200" label="创建时间" />
      <el-table-column prop="updatedAt" width="200" label="更新时间" />
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import { getListCategory, getSmartReportFormList } from '@/apis/reportService.js'
export default {
  name: 'CreateOrEditStep1',
  mixins: [paginationMixin],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    showTypeText: Function
  },
  data() {
    return {
      loading: false,
      addOrEditLoading: false,
      classifyList: [],
      tableData: [],
      search: {
        categoryId: ''
      }
    }
  },
  computed: {
    multipleSelection: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {
    this.getClassifyList()
  },
  methods: {
    async getDataList() {
      if (this.loading) return
      this.loading = true
      if (this.search.categoryId === '0') {
        this.search.categoryId = ''
      }
      const params = this.getParams(this.search)
      try {
        const { data = [], totalCount } = await getSmartReportFormList(params)
        this.tableData = data
        this.pagination.total = totalCount

        this.toggleSelection(this.multipleSelection, (item, row) => item.modelId === row.modelId)
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    async getClassifyList() {
      try {
        this.addOrEditLoading = true
        const res = await getListCategory({
          pageNo: 1,
          pageSize: 9999
        })
        this.classifyList = res
        this.getDataList()
        this.addOrEditLoading = false
      } catch (error) {
        this.addOrEditLoading = false
        console.log(error)
      }
    },
    // 选择某些行
    toggleSelection(arr = [], func) {
      if (!arr?.length) return
      this.tableData.forEach(row => {
        arr.forEach(item => {
          if (func(item, row)) {
            this.$refs.table.toggleRowSelection(row)
          }
        })
      })
      // this.$refs.table.clearSelection()
    },
    submit() {
      return new Promise((resolve, reject) => {
        if (this.multipleSelection.length > 0) {
          resolve(this.multipleSelection)
        } else {
          this.$message.error('请选择报表模板')
          reject()
        }
      })
    },
    handleClick() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.create-or-edit-step1 {
  .pagination-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  :deep(.el-table__header-wrapper) .el-checkbox {
    display: none;
  }
}
</style>
